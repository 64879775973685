import React from "react"
import { graphql } from 'gatsby';
import Layout from "@components/kaigai-fx/layout"
import FixedFoot from "@components/kaigai-fx/fixedFooter.js";

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';
import icon_post_SVG3 from '@images/kaigai-fx/post_icon3.svg';

// コンポーネント呼び出し
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成

const FxPrivacyPolicy = ({data}) => {
  const indexUrl = '/kaigai-fx/';
  const pageTitle = 'プライバシーポリシー'
  const searchList = SearchCategList().Main; // カテゴリ検索リスト
  
  // Seo情報設定
  const pathName = 'privacy-policy';
  const seoTag = SeoContents({ Path:pathName });

  // 関連記事取得(components/related-article.js)
  const getArchiveVisual = data.imagesettingData.edges.filter(item => item.node.slug === 'archive-visual')[0];
  const tagArray = [
    {name: "pickup", slug: "pickup"},
    {name: "おすすめ", slug: "recommend"}
  ]       
  const relatedList = RelatedContents([''], tagArray, '', getArchiveVisual); 
      
  // ワード検索
  const wordSearchContentPC = WordSearch().PC;
  const wordSearchContentMB = WordSearch().MB;

  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB

  return (
    <>
      <Layout>
        {seoTag}
        <div className="md:max-w-6xl md:flex justify-between mx-auto pt-5 md:pt-12"> 
          <div className="policy_cont max-w-4xl pb-5 px-4 mb-10 md:pb-0 md:mb-0 w-full">
            <div className="text-left mb-3 mx-auto flex items-center">
              <p><img alt="" src={icon_t_SVG1}/></p>
              <h1 className="ml-5 font-semibold tracking-wide text-[24px] md:text-[56px] text-[#36A7C3] table" itemProp="headline">{pageTitle}</h1>
            </div>

            {/* パンくず */}
            <p className="text-[12px] mb-[20px] md:mb-[30px]">
              <div className="flex mb-2 md:mb-3">
                <img className="pr2" alt="" src={icon_post_SVG3}/>
                <a href={indexUrl} className="px-2 hover:text-gray-400">ホーム</a>
                <span className="pr-2 mx-0 my-auto inset-y-0 md:m-0">＞</span>
                <span className="pr-2">{pageTitle}</span>
              </div>
            </p>

            {/* １カラムコンテンツ */}
            <div>
              <div className="max-w-xl w-full mx-auto md:pb-11 md:max-w-5xl mb-10">
                <h2>プライバシーについて</h2>
                <p className="text-[12px]">「株式会Enzine」(以下、「弊社」といいます)は、弊社の運営するウェブまたはモバイルサイト(以下、「運営サイト」といいます)をご利用の際にご提供いただくサービス利用者(以下、「ユーザ」といいます)の氏名、ご連絡先等の個人を特定しうる情報(以下、「個人情報」といいます) の重要性を認識し、以下の取り組みを実施して個人情報を慎重に取扱い、プライバシーの保護に努めております。</p>
                <h2>個人情報の登録について</h2>
                <p className="text-[12px]">運営サイトをご利用いただく場合、ユーザには必要に応じて、ご自身の個人情報を入力または登録していただくことがあります。</p>
                <h2>個人情報の管理について</h2>
                <p className="text-[12px]">運弊社は、ユーザに登録して頂いた個人情報を適切、厳重に管理し、ユーザの個人情報への不正なアクセスや情報の紛失、破壊、改ざんおよび漏洩等が起きぬよう、予防ならびに安全対策を講じております。<br></br>
                なお、個人情報を保管するデータベースサーバは、24時間管理のセキュリティ設備のある、データセンターで厳重に管理されています。また、このサーバに保管されているデータへのアクセスは、弊社、または、弊社の契約する運用代行会社の一部の人間に厳しく制限されています。</p>
                <h2>個人情報の利用について</h2>
                <p className="text-[12px]">以下の何れかに該当する場合を除き、弊社が個人情報を利用することはありません。<br></br>
                (1) ユーザの同意を得た場合、またはユーザの個人情報の確認の為、必要に応じてユーザの個人情報をお伺いする場合。<br></br>
                (2) 弊社からメールマガジン等で、ユーザに情報を提供する場合。<br></br>
                (3) 統計情報をサービス向上や合法的な目的のために使用する場合。<br></br>
                (4) 弊社がサービスを提供する上で、ユーザへ必要かつ有益と思われるご連絡事項・情報を発信する場合。<br></br>
                (5)個人データの管理業務を委託された場合。<br></br>
                (6)封入、封緘、発送の業務を委託された場合。<br></br>
                (7)ユーザからのお問い合わせに対応する場合。<br></br>
                (8)インタビューの模様等を撮影した画像、音声をユーザの同意を得て公開する場合。</p>

                <h2>個人情報の第三者への提供について</h2>
                <p className="text-[12px]">以下の何れかに該当する場合を除き、弊社がユーザの個人情報を第三者に提供、開示等をすることはありません。<br></br>
                (1) あらかじめ弊社と機密保持の契約を締結している会社、金融機関等に、弊社の提供するサービスに関連して、その準備、履行またはアフターサービス等に必要な範囲で提供・開示する場合。<br></br>
                (2) 法令等により開示が求められる場合。<br></br>
                (3) ユーザ及び公衆の生命、健康、財産などに重大な損害が発生することを防止する為(公共の利益のため)に必要であると考えられる場合。<br></br>
                (4) ユーザの同意を得た場合。<br></br>
                但し、ユーザの同意に基づき個人情報を提供する会社には、ユーザの個人情報を漏洩や再提供等しないよう、契約により義務づけ、適切な管理を実施させます。</p>
                <h2>個人情報の第三者への提供について</h2>
                <p className="text-[12px]">以下の何れかに該当する場合を除き、弊社がユーザの個人情報を第三者に提供、開示等をすることはありません。<br></br>
                (1) あらかじめ弊社と機密保持の契約を締結している会社、金融機関等に、弊社の提供するサービスに関連して、その準備、履行またはアフターサービス等に必要な範囲で提供・開示する場合。<br></br>
                (2) 法令等により開示が求められる場合。<br></br>
                (3) ユーザ及び公衆の生命、健康、財産などに重大な損害が発生することを防止する為(公共の利益のため)に必要であると考えられる場合。<br></br>
                (4) ユーザの同意を得た場合。<br></br>
                但し、ユーザの同意に基づき個人情報を提供する会社には、ユーザの個人情報を漏洩や再提供等しないよう、契約により義務づけ、適切な管理を実施させます。</p>
                <h2>クッキーについて</h2>
                <p className="text-[12px]">以下の何れかに該当する場合を除き、弊社がユーザの個人情報を第三者に提供、開示等をすることはありません。<br></br>
                運営サイトでは、ユーザに一層便利にご利用いただくため、又、閲覧履歴を採取するために「クッキー」という技術を使っているページがあります。<br></br>
                「クッキー」は、ウェブサイトからユーザのコンピュータを識別する技術です。<br></br>
                ユーザは、ブラウザの設定によりクッキーの受取りを拒否したり、クッキーを受け取ったとき警告を表示させたりすることができます。<br></br>
                ユーザがクッキーの受取りを拒否された場合も運営サイトをご利用いただけますが一部機能がご利用になれない場合があります。</p>
                <h2>閲覧履歴の採取について</h2>
                <p className="text-[12px]">運営サイトでは、ユーザの閲覧履歴情報を収集しております。収集される情報は、運営サイトの利用状況の把握およびサービスの企画、向上のための集計、分析に用い、それ以外に利用することはありません。</p>
                <h2>トラフィックデータ収集</h2>
                <p className="text-[12px]">運営サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。<br></br>このツールではトラフィックデータ収集のためにクッキー情報を使用しております。<br></br>トラフィックデータは匿名で収集されており、個人を特定するものではございません。また、クッキーを無効にすることで収集を拒否することが可能ですので、お使いのブラウザの設定をご確認ください。</p>
                <h2>個人情報収集の目的</h2>
                <p className="text-[12px]">個人情報は、サービスの向上やユーザからのお問い合わせ、商品の発送等に対応することを目的として、必要な範囲内で収集しております。</p>
                <h2>変更及び修正</h2>
                <p className="text-[12px]">本プライバシーポリシーの内容は､サービス内容や技術動向の変化、法令の改正等に従って予告なく変更及び修正されることがあります｡</p>
                <h2>ご意見、お問い合わせ等の受付窓口に関する事項</h2>
                <p className="text-[12px]">当社の個人情報の取扱いに関するご意見、お問い合わせ等につきましては、下記の窓口にご連絡ください。<br></br>
                なお、直接の来社によるお問い合わせにつきましてはお断りさせていただいておりますので、予めご了承ください。<br></br>
                株式会社Enzine<br></br>
                »お問い合わせフォーム</p>
                <h2>その他</h2>
                <p className="text-[12px]">（1）弊社が、ユーザから自己の情報について開示、確認、訂正、削除等を求められた場合は、合理的な期間内にこれに対応致します。<br></br>
                （2）運営サイトは、運営サイトが保有する個人情報に関して適用される法令、規範等を遵守するとともに、個人情報の取扱いに関する取り組みを適宜見直し、改善していきます。<br></br>
                （3）弊社は、運営サイトからリンクしている他のウェブサイトにおけるユーザの個人情報の安全確保については、責任を負うことはできません。<br></br><br></br>
                ユーザご自身にて、弊社以外のウェブサイトにおける個人情報についての取扱い基準等の内容を吟味し、取扱い基準等が定められていない場合には当該ウェブサイトの担当部署・担当者に直接確認されるなどして、ご自身の個人情報の安全を確認されるようお勧めいたします。</p>
              </div> 
            </div>
          </div>
          
          <div className="column2_filed px-4">
            

            {/*カテゴリ検索 */}
            <div>
              {searchList}
            </div>

            {/*ワード検索 */}
            {wordSearchContentPC}
            
            <div className="md:sticky">
              {/*関連記事 */}
              <h3 className="text-[#36A7C3] mt-13 md:mt-10 mb-5 font-semibold flex">関連記事</h3>
              <div className="flex-wrap justify-around mb-10 md:block flex">
                {relatedList}
              </div>
            </div>
            
            {/* PCプロフィール */}
            {profilePC}
          
          </div>
        </div>
        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}

         {/* MBプロフィール */}
          {profileMB}
          
        </div>
        {/* 固定フッター */}
        <FixedFoot fixPattern="nomal" item="" />
      </Layout>
    </>
  )
}

export const query = graphql`
  query privacyquery {
    imagesettingData: allWpImagesettings {
      edges {
        node {
          title
          slug
          smartCustomFields {
            broker_name
            archive_text1
            archive_text2
            text_position
            font_color
            image
          }
          imagesetting {
            bannerSettingsGroup {
              fieldGroupName
              bannerArea1 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea2 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea3 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default FxPrivacyPolicy